@import 'src/theme/variables';
.Activity {
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: $gray-700;
    font-weight: 200 !important;
  }
}
.activity-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  p {
    margin-left: 8px;
  }

}

.activity-graphs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  margin-right: 2.5%;
  margin-left: 2.5%;
}
.activity-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 10px);
  min-width: 150px;
  height: 30vh;
  min-height: 250px;
}

@media only screen and (max-width: 600px) {
  .activity-graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 150px;
    height: 30vh;
    min-height: 250px;
  }
}

.stats-container {
  display: flex;
  align-items: center;

  width: 100%;
  height: 4rem;

  .stat {
    flex: 1;
    justify-content: start;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    color: rgb(70, 152, 203);
    font-weight: 200;
    font-size: 2.25rem;
    line-height: 2.6rem;

    &-symbol {
      display: inline-block;

      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: #929292;
      font-weight: 400;
      font-size: 1rem;
      font-size: 0.71428571rem;

      line-height: 1rem;
      vertical-align: middle;
      border: 1px solid #929292;
      border-radius: 3px;
    }
  }
  .label {
    font-weight: 400;
    font-size: 0.8rem;
    letter-spacing: 0.03em;
  }
}
